import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Portfolios `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-stocks/"
            }}>{`All-Stars Stocks`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: leveraged growth`}</li>
        <li parentName="ul">{`Type: meta-portfolio`}</li>
        <li parentName="ul">{`Invests in: individual stocks, leveraged ETFs tracking bonds, commodities`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly/ monthly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $100,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`All-Stars Leveraged`}</em>{` aims to outperform the S&P 500 by applying +33% leverage while carefully considering portfolio risk. The meta-portfolio combines two proprietary momentum strategies with a 2x leveraged mean-variance strategy, resulting in solid outperformance of the stock market at comparable risk. With its hybrid weekly/ monthly rebalancing schedule, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` has moderate maintenance requirements, appealing to busy and aggressive investors with larger accounts.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, All-Stars Leveraged: A leveraged meta-strategy",
                "title": "TuringTrader.com, All-Stars Leveraged: A leveraged meta-strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/all-stars-portfolios/"
            }}>{`All-Stars Portfolios`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`All-Stars Leveraged`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`divide capital into three equal-sized tranches`}</li>
        <li parentName="ul">{`allocate one tranche each to TuringTrader's `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round Robin`}</a>{`, `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/tt-mach-2/"
          }}>{`Mach-2`}</a></li>
        <li parentName="ul">{`rebalance between tranches once per month`}</li>
      </ul>
      <p>{`By combining three portfolios, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` diversifies across management techniques. As a result, the meta-strategy achieves higher risk-adjusted returns than its components.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`In bullish markets, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` has up to 133% exposure to the U.S. stock market. By investing in various broad market indices along with individual stocks, the portfolio significantly reduces concentration risk. During bear markets, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` invests in various bonds and gold to profit from their negative correlation to the stock market.`}</p>
      <p>{`To achieve diversification spanning multiple asset classes, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` relies on `}<em parentName="p">{`serial  diversification`}</em>{` over the entire economic cycle. Because the meta-portfolio combines three different mechanisms, it ensures diversification across trading styles and improves its ability to cope with a broad range of market conditions. The portfolio's beta is approximately 0.5, showing how well the tactical management decouples the returns from the S&P 500.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`All-Stars Leveraged`}</em>{` handily beats the S&P 500 benchmark in most years. Periods of temporary underperformance are rare and brief. As a result, the portfolio continually expands its lead over the benchmark.`}</p>
      <p>{`Overall, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` delivers impressive returns. While the portfolio's volatility is about on-par with the S&P 500, it achieves an overall much lower risk profile. The Monte-Carlo simulation confirms these claims of a massive upside and a much-reduced risk over the benchmark.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`All-Stars Leveraged`}</em>{` trades frequently and regularly triggers taxable events. Investors should expect most of the portfolio's capital gains to be short-term. Therefore, the portfolio works best in tax-deferred accounts. However, because of its outsized returns, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` also adds tremendous value to taxable accounts.`}</p>
      <p><em parentName="p">{`All-Stars Leveraged`}</em>{` makes use of 2x leveraged ETFs which are considered high-risk instruments. Many brokerages require signing additional disclosures before allowing investors to use these instruments in their accounts. Because we use these products sparsely and in combination with tactical management, we believe our use of these instruments to be responsible.`}</p>
      <p>{`To allow for proper position sizing, especially of the potentially expensive individual stock components, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` requires a minimum investment of $100,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, August 2020`}</a>{`: Initial release, combining `}<a parentName="li" {...{
            "href": "/portfolios/tt-dos-equis/"
          }}>{`Dos Equis`}</a>{`, `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/tt-mean-kitty/"
          }}>{`MeanKitty`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, April 2021`}</a>{`: New release, combining `}<a parentName="li" {...{
            "href": "/portfolios/tt-mach-2/"
          }}>{`Mach-2`}</a>{`, `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`RoundRobin`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=3"
          }}>{`v3, April 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-mach-2/"
          }}>{`Mach-2`}</a>{` and `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{` to their latest revisions.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=4"
          }}>{`v4, October 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-mach-2/"
          }}>{`Mach-2`}</a>{` and `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round Robin`}</a>{` to their latest revisions.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      